import Auth from '@aws-amplify/auth'
// @ts-ignore
import {
    Category,
    Configuration,
    EmissionFactor,
    EmissionFactorAPIApi,
    MonthlyOrganizationSummary,
    OrganizationAPIApi,
    OrganizationSummaryAPIApi,
    OrganizationIdeaVersionAPIApi,
    Scope,
    ScopesCategoriesAPIApi,
    Site,
    SiteActivityAPIApi,
    SiteAPIApi,
    SiteCategoryTransactionAPIApi,
    SiteSubmissionAPIApi,
    SiteSummaryAPIApi,
    SiteNodeAPIApi,
    UserAPIApi,
    NotificationAPIApi,
    AuthAPIApi,
    SiteOrganizationBatchUpdateAPIApi,
    AntiWarmingReportApi,
    EnergySavingReportApi,
    EnergySavingLevelApi,
    IdeaVersionAPIApi,
} from '@/openapi'
import { navigate } from 'gatsby'

// =================================================================================
// Open API Config
// =================================================================================
export const configureGhgApi = (baseUrl: string) => {
    const openApiConfig = new Configuration({
        basePath: baseUrl,
        accessToken: () =>
            Auth.currentSession()
                .then((session) => session.getAccessToken().getJwtToken())
                .catch((err) => {
                    console.warn(err)
                    navigate('/')
                    return ''
                }),
    })
    siteOrganizationBatchUpdateApi = new SiteOrganizationBatchUpdateAPIApi(openApiConfig)
    emissionFactorApi = new EmissionFactorAPIApi(openApiConfig)
    organizationApi = new OrganizationAPIApi(openApiConfig)
    organizationSummaryApi = new OrganizationSummaryAPIApi(openApiConfig)
    organizationIdeaVersionApi = new OrganizationIdeaVersionAPIApi(openApiConfig)
    scopesCategoriesApi = new ScopesCategoriesAPIApi(openApiConfig)
    siteApi = new SiteAPIApi(openApiConfig)
    siteActivityApi = new SiteActivityAPIApi(openApiConfig)
    siteCategoryTransactionApi = new SiteCategoryTransactionAPIApi(openApiConfig)
    siteSubmissionApi = new SiteSubmissionAPIApi(openApiConfig)
    siteSummaryApi = new SiteSummaryAPIApi(openApiConfig)
    siteNodeApi = new SiteNodeAPIApi(openApiConfig)
    userApi = new UserAPIApi(openApiConfig)
    notificationApi = new NotificationAPIApi(openApiConfig)
    authApi = new AuthAPIApi(openApiConfig)
    antiWarmingReportApi = new AntiWarmingReportApi(openApiConfig)
    energySavingReportApi = new EnergySavingReportApi(openApiConfig)
    energySavingLevelApi = new EnergySavingLevelApi(openApiConfig)
    ideaVersionApi = new IdeaVersionAPIApi(openApiConfig)
}

// =================================================================================
// APIs
// =================================================================================
export let emissionFactorApi: EmissionFactorAPIApi
export let organizationApi: OrganizationAPIApi
export let organizationSummaryApi: OrganizationSummaryAPIApi
export let organizationIdeaVersionApi: OrganizationIdeaVersionAPIApi
export let scopesCategoriesApi: ScopesCategoriesAPIApi
export let siteApi: SiteAPIApi
export let siteActivityApi: SiteActivityAPIApi
export let siteCategoryTransactionApi: SiteCategoryTransactionAPIApi
export let siteSubmissionApi: SiteSubmissionAPIApi
export let siteSummaryApi: SiteSummaryAPIApi
export let siteNodeApi: SiteNodeAPIApi
export let userApi: UserAPIApi
export let notificationApi: NotificationAPIApi
export let authApi: AuthAPIApi
export let siteOrganizationBatchUpdateApi: SiteOrganizationBatchUpdateAPIApi
export let antiWarmingReportApi: AntiWarmingReportApi
export let energySavingReportApi: EnergySavingReportApi
export let energySavingLevelApi: EnergySavingLevelApi
export let ideaVersionApi: IdeaVersionAPIApi

// =================================================================================
// Types
// =================================================================================
export type Year = ReturnType<Date['getFullYear']>
export type Month = string

// Quick and dirty cause we can't extend enums in TS: https://github.com/microsoft/TypeScript/issues/17592
export enum InputStatus {
    DONE = 'done', // SubmissionStatus.Done
    IN_PROGRESS = 'in progress', // SubmissionStatus.InProgress
    NOT_NEEDED = 'not needed',
    INACITVE = 'inactive',
}

export namespace Summary {
    export interface HasMonth {
        month: Month
    }
    export interface ScopesSummary {
        scopes: Array<Summary.ScopeSummaryItem>
    }

    export interface Monthly {
        month: Month
        quantity: number
        scopes: Array<Summary.ScopeSummaryItem>
        sites?: Array<Summary.SiteSummaryItem>
    }

    export interface Annual {
        year: Year
        quantity: number
        scopes: Array<Summary.ScopeSummaryItem>
        months: Array<MonthlyOrganizationSummary>
        sites?: Array<Summary.SiteSummaryItem>
    }

    export type CategorySummaryItem = {
        id: EmissionFactor['id']
        name: EmissionFactor['name']
        quantity: number
        categoryEmissionFactorTableId: number
    }

    export type CategorySummary = {
        id: Category['id']
        name: string
        quantity: number
        highestEmissionFactors: Array<CategorySummaryItem>
        highestEmissionFactorTables: Array<CategorySummaryItem>
    }

    export type ScopeSummaryItem = {
        id: Scope['id']
        quantity: number
        categories: Array<CategorySummary>
    }

    export type SiteSummaryItem = {
        id: Site['id']
        name: Site['name']
        quantity: number
        scopes: Array<ScopeSummaryItem>
    }
}

// =================================================================================
//  API Helper Functions
// =================================================================================
export function appendQueryParams(queryParams: { [key: string]: any }, url: string): string {
    url += '?'
    for (const [key, value] of Object.entries(queryParams)) {
        url += `${key}=${value}&`
    }
    url = url.slice(0, -1)
    return url
}

export function makeMonth(year: number, month: number): Month {
    return `${year}-${String(month).padStart(2, '0')}`
}
